import React, { useState, useEffect } from "react";
import bgParalax from "./images/bg-parallax4.jpg";
import SectionHeader from "../../../components/SectionHeader/index";
import useFetch from "../../../hooks/useFetch";
import { remote } from "../../../services/api";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faLeanpub } from "@fortawesome/free-brands-svg-icons";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import WhatsappButton from "../../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function ProgramsDetail(props) {
  const [program, setDataProgram] = useState([]);

  const { response: ProgramEndpoint } = useFetch({
    api: remote,
    url: `/program/${props.match.params.id}`,
  });

  const getPaginationHistory = () => {
    const { state } = props.location;
    if (state && state.data) {
      return state.data;
    }
    return {};
  };

  useEffect(() => {
    if (ProgramEndpoint !== null) {
      setDataProgram(ProgramEndpoint);
    }
  }, [ProgramEndpoint]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={`PROGRAMA DE CAPACITACIÓN PARA ${program.category}`}
        parent={{
          title: "Programas",
          url: "/programas",
          pagination: getPaginationHistory(),
        }}
      />
      <section
        className={_classNames("mainContent", "blogPosts", "courseSingle")}
      >
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.blogTitleSingle}>
                <h1 className={_classNames("bold")}>{program.title}</h1>
                <Container>
                  <Row>
                    <Col md={6} className={styles.featurePost}>
                      <img src={program.image} alt={program.title} />
                    </Col>
                    <Col md={6} className={styles.courseWidgetPrice}>
                      <h4 className={styles.courseTitle}>Detalles</h4>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faClock} />
                          <span>Fecha</span>
                          <span className={styles.time}>
                            {program.full_date}
                          </span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <span>Duración</span>
                          <span className={styles.time}>{`${
                            program.duration || 0
                          } HRS`}</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faLeanpub} />
                          <span>Modulos</span>
                          <span className={styles.time}>{program.module}</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faGraduationCap} />
                          <span>Lugar</span>
                          <span className={styles.time}>{program.place}</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCreditCard} />
                          <span>Costo</span>
                          <span className={styles.time}>{program.price}</span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
            <Col md={12} className={styles.entryContent}>
              <h4 className={_classNames("bold", "mgbt17")}>Descripción</h4>
              <p className={styles.mgbt48} style={{ textAlign: "justify" }}>
                {program.description &&
                  program.description.split("\n").map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </p>
              <h4 className={_classNames("bold", "mgbt17")}>
                Detalles del Curso
              </h4>
              <ul className={styles.curriculum}>
                {(program.modules || []).map((module, keyModule) => {
                  return (
                    <li className={styles.section} key={keyModule}>
                      <h4 className={styles.sectionTitle}>{module.title}</h4>
                      <h4
                        className={styles.sectionTitle}
                      >{`Duración ${module.duration} Hrs.`}</h4>
                      <ul className={styles.sectionContent}>
                        {module.topics.map((topic, key) => {
                          return (
                            <li className={styles.courseLesson} key={key}>
                              <Link to="#" className={styles.lessonTitle}>
                                {topic.topic}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
