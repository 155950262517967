import React, { useState, useEffect } from "react";
import SectionHeader from "../../components/SectionHeader/index";
import bgParalax from "./images/bg-parallax7.jpg";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import useFetch from "../../hooks/useFetch";
import { remote } from "../../services/api";
import chunk from "lodash/chunk";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Clients() {
  const [clients, setDataClients] = useState([]);

  const { response: ClientsEndpoint } = useFetch({
    api: remote,
    url: "/clients?order=asc&limit=none",
  });

  useEffect(() => {
    if (ClientsEndpoint !== null) {
      setDataClients(ClientsEndpoint);
    }
  }, [ClientsEndpoint]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let rows = [];

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={1000}
        title={"CLIENTES ACTIVOS"}
        parent={{ title: "Clientes" }}
      />
      <section className={_classNames("flatRow", "padTop96", "padBottom100")}>
        <Container>
          {(clients.data || []).forEach((item, key) => {
            rows.push(
              <Col md={3} sm={6} key={key} className={styles.clientCol}>
                <div className={styles.imgContainer}>
                  <img src={item.big_image} alt={item.title} />
                </div>
              </Col>
            );
          })}
          {chunk(rows, 4).map(function (group, key) {
            return (
              <Row className={styles.clientCustomRow} key={key}>
                {group}
              </Row>
            );
          })}
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
