import React from "react";
import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import classNames from "classnames/bind";
import styles from "../../styles/shorcodes.module.scss";
import { defaultCategoryData } from "../../shared/data";
const _classNames = classNames.bind(styles);

export default function CoursesSlider(props) {
  const settings = {
    lazyload: true,
    nav: false,
    loop: true,
    gutter: 30,
    mouseDrag: true,
    items: 3,
    autoplay: true,
    autoplayButtonOutput: false,
    controls: false,
    responsive: {
      360: {
        items: 1,
      },
      991: {
        items: 3,
      },
    },
  };

  const limit = 9;

  const defaultCategory = defaultCategoryData(limit);

  return (
    <TinySlider settings={settings}>
      {props.content.data.map((item, key) => (
        <div key={key} className={styles.flatCourse}>
          <Link
            to={{
              pathname: `programas/detalle/${item.id}`,
              state: {
                data: defaultCategory,
              },
            }}
          >
            <img src={item.image} alt="" />
          </Link>
          <div className={styles.courseContent}>
            <h4>
              <Link
                to={{
                  pathname: `programas/detalle/${item.id}`,
                  state: {
                    data: defaultCategory,
                  },
                }}
              >
                {item.title}
              </Link>
            </h4>
            <div className={styles.price}>{item.price}</div>
            <p>{item.short_description}</p>
            <ul className={_classNames("courseMeta", "desc")}>
              <li>
                <h6>{item.module}</h6>
                <span> Modulo</span>
              </li>
              <li>
                <h6>{`${item.duration} Horas`}</h6>
                <span> Duracción</span>
              </li>
              <li>
                <h6>
                  <span>{item.date}</span>
                </h6>
                <span> Fecha</span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </TinySlider>
  );
}
