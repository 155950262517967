import React, { useState } from "react";
import SectionHeader from "../../components/SectionHeader/index";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Parallax } from "react-parallax";
import bgParalax from "./images/bg-parallax4.jpg";
import bgParalax1 from "./images/bg-parallax1.jpg";
import director from "./images/director.png";
import { InView } from "react-intersection-observer";
import CountUp from "react-countup";
import WhatsappButton from "../../components/WhatsappButton/index";

const _classNames = classNames.bind(styles);

export default function About() {
  const [aboutSubSection, setSubSection] = useState("mision");

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={"Nuestra Historia"}
        parent={{ title: "Nosotros" }}
      />
      <div className={_classNames("flatRow", "course", "rowBg", "justify")}>
        <Container>
          <Row>
            <Col md={12} sm={12} className={styles.flatPdr100}>
              <h1 className={styles.titleCourse}>
                Descripción Del Instituto QRES
              </h1>
              <p className={_classNames("flatLh25")}>
                <strong>
                  Instituto QRES Capacitación Evaluación Y Certificación En
                  Seguridad Integral S. de R.L. de C.V.
                </strong>{" "}
                Nació de la imperiosa necesidad de capacitar a todo el personal
                de seguridad privada misma que está legalmente constituida para
                llevar a cabo sus objetos sociales.
              </p>

              <p>
                Así mismo cuenta con autorización y registro de la secretaria de
                trabajo y previsión social como agente capacitador externo con
                el numero QCE-190321-TW7-0013
              </p>

              <p>
                La cual se expidió con el fundamento en los artículos 153-A
                párrafo tercero de la ley federal del trabajo 17bis de la ley
                orgánica de la administración pública federal octavo transitorio
                del decreto por el que se reforman, adicionan y derogan diversas
                disposiciones de la ley orgánica de la administración pública
                federal publicado en el diario oficial de la federación el 30 de
                noviembre de 2018,31 fracción xxi del reglamento interior de la
                secretaria de trabajo y previsión social publicado en el diario
                oficial de la federación el 30 de julio de 2014,15 y 17 del
                acuerdo que se dan a conocerlos criterios administrativos
                requisitos y formatos para realizar los trámites y solicitar los
                servicios en materia de capacitación, adiestramiento y
                productividad de los trabajadores publicado el 14 de junio de
                2013 y su modificación el 16 de noviembre de 2017 en el diario
                oficial de la federación folio 8795/2019 autorizado el 17 de
                julio del 2919.
              </p>

              <p>
                {" "}
                <strong>
                  {" "}
                  Instituto QRES Capacitación Evaluación Y Certificación En
                  Seguridad Integral S. de R.L. de C.V.{" "}
                </strong>{" "}
                Promueve el constante avance hacia la profesionalización de la
                seguridad, capacita, entrena evalúa y actualiza integralmente a
                personas naturales y jurídicas en seguridad privada inteligente
                con base en los principios{" "}
                <strong>
                  (FIESTAINSEPRI) “Filosofía Estratégica Táctica E Inteligente
                  En Seguridad Privada Integral,{" "}
                </strong>{" "}
                basada en el uso de la inteligencia práctica técnica y táctica
                en lugar del uso de la fuerza física y de las armas. La cual
                orienta todas sus acciones dentro del marco de la inteligencia,
                prudencia astucia, prevención y observación.
              </p>

              <p>
                Inculcar la práctica de utilizar todas las facultades y
                habilidades que subyacen en cada uno de nosotros y reconocer el
                rol de la intuición estratégica como herramienta fundamental
                para generar ideas, oportunidades de negocio y tomar decisiones
                acertadas en escenarios nuevos.
              </p>

              <p>
                Posee clientes cautivos y se encuentran en la búsqueda de nuevos
                horizontes.
              </p>

              <p>
                Sus dueños pretenden de este instituto lograr un lugar
                diferenciado en el mercado del resto de las empresas o centros
                de capacitación o capacitadores en seguridad privada, con
                personal de instructores agentes capacitadores externos
                excelentemente bien entrenados y certificados para desarrollar
                su capacitación y evaluación al personal de, guardias , jefes de
                servicio, supervisores, coordinadores, gerentes, y directores
                operativos en seguridad privada que hagan la diferencia, para
                que la empresa de seguridad pueda ofrecer un servicio de calidad
                y excelencia con valor agregado para sus respectivos clientes
                usuarios y la comunidad en general.
              </p>

              <p>
                Los objetivos apuntan, a que todo el personal operativo y
                administrativo de seguridad privada tengan una buena atención al
                cliente, resuelvan problemas, prevengan riesgos, cuiden
                eficazmente a las personas y sus bienes y que sus clientes se
                sientan protegidos y resguardados de por vida.
              </p>

              <p>
                El instituto es una empresa familiar, los socios son los
                directivos de esta y las distintas áreas que la conforman
                dependen de las decisiones del directorio. Sus socios fundadores
                son exmilitares cuya experiencia fue adquirida durante muchos
                años en el ejército y fuerza aérea mexicanos, así como en la
                administración de justicia en dependencias gubernamentales tales
                como la fiscalía general de la república y procuraduría general
                de justicia militar y luego se radicaron en la ciudad de zapopan
                jalisco instalando este instituto qres capacitación evaluación y
                certificación en seguridad integral, S. de R.L. de C.V.
              </p>

              <p>
                Quienes tienen una trayectoria aproximada de 38 años de
                experiencia en la seguridad privada integral, es un instituto
                joven y se encuentra en pleno desarrollo y crecimiento.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={_classNames("flatRow", "padTop100", "flatAbout")}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={_classNames("flatTabs", "aboutUs")}>
                <ul className={styles.menuTab}>
                  <li
                    className={_classNames({
                      active: aboutSubSection === "mision",
                    })}
                  >
                    <Link to="#" onClick={() => setSubSection("mision")}>
                      Misión
                    </Link>
                  </li>
                  <li
                    className={_classNames({
                      active: aboutSubSection === "vision",
                    })}
                  >
                    <Link to="#" onClick={() => setSubSection("vision")}>
                      Visión
                    </Link>
                  </li>
                  <li
                    className={_classNames({
                      active: aboutSubSection === "filosofia",
                    })}
                  >
                    <Link to="#" onClick={() => setSubSection("filosofia")}>
                      Filosofía
                    </Link>
                  </li>
                  <li
                    className={_classNames({
                      active: aboutSubSection === "objetivos",
                    })}
                  >
                    <Link to="#" onClick={() => setSubSection("objetivos")}>
                      Objetivos
                    </Link>
                  </li>
                </ul>
                <div className={_classNames("contentTab", "clearfix")}>
                  <div
                    className={_classNames("contentInner", {
                      active: aboutSubSection === "mision",
                    })}
                  >
                    <div className={styles.textTab}>
                      <div className={styles.fletTitle}>
                        <h1>
                          MISIÓN<span></span>
                        </h1>
                      </div>
                      <p>
                        Promover la formación integral en seguridad inteligente
                        mediante cursos académicos para fortalecer la
                        cooperación interinstitucional, la vinculación con el
                        medio y el desarrollo en el entorno globalizado de la
                        seguridad privada.
                      </p>
                    </div>
                  </div>

                  <div
                    className={_classNames("contentInner", {
                      active: aboutSubSection === "vision",
                    })}
                  >
                    <div className={styles.textTab}>
                      <div className={styles.fletTitle}>
                        <h1>
                          VISIÓN<span></span>
                        </h1>
                      </div>
                      <p>
                        Ser líder y reconocido por el fomento del aprendizaje
                        continuo hacia la profesionalización de la seguridad
                        privada apoyada en la excelencia académica mediante
                        estrategias educativas, tecnológicas y de desarrollo en
                        el país.
                      </p>
                    </div>
                  </div>

                  <div
                    className={_classNames("contentInner", {
                      active: aboutSubSection === "filosofia",
                    })}
                  >
                    <div className={styles.textTab}>
                      <div className={styles.fletTitle}>
                        <h1>
                          FILOSOFÍA<span></span>
                        </h1>
                      </div>
                      <p>
                        Instituto qres capacitación evaluación y certificación
                        en seguridad integral S. de R.L. de C.V. es una manera
                        de ser o de vivir, con esfuerzos selectivos de
                        experiencias, con el fin de predecir soluciones no
                        comunes en situaciones de alto riesgo.
                      </p>
                    </div>
                  </div>

                  <div
                    className={_classNames("contentInner", {
                      active: aboutSubSection === "objetivos",
                    })}
                  >
                    <div className={styles.textTab}>
                      <div className={styles.fletTitle}>
                        <h1>
                          OBJETIVOS<span></span>
                        </h1>
                        <div className={styles.decs}>
                          Los objetivos del instituto qres para las empresas de
                          seguridad privada son:
                        </div>
                      </div>

                      <ul className={styles.flatList}>
                        <li>
                          <div className={styles.content}>
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div>
                              I. Asesorar a sus clientes en el sistema de
                              seguridad privada más conveniente de acuerdo con
                              las necesidades y expectativas. Ofrecer la mejor
                              cobertura en seguridad física y electrónica a las
                              instalaciones con su personal operativo y
                              administrativo capacitado.
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className={styles.content}>
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div>
                              II. Proteger cuidar y conservar las instalaciones
                              de los clientes a través de la capacitación y
                              prevención de riesgos delitos y siniestros.
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className={styles.content}>
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div>
                              III. Garantizar la capacitación a su personal
                              operativo y administrativo para que otorguen un
                              servicio de alta calidad a través del eficiente
                              desempeño de un buen y organizado equipo de
                              trabajo.
                            </div>
                          </div>
                        </li>
                      </ul>

                      <br />
                      <br />

                      <h1>OBJETIVO GENERAL</h1>

                      <p>
                        A. Se elabora y se propone un plan de capacitación, en
                        temas inherentes al puesto de trabajo, de carácter
                        general y específico para toda la dotación de guardias
                        de seguridad privada en curso básicos y actualizaciones
                        de acuerdo con una calendarización anual en los
                        diferentes puestos laborales para mejorar el desempeño y
                        en consecuencia de la calidad y excelencia del servicio
                        que se otorgara al cliente de los diferentes sectores.
                      </p>

                      <p>
                        B. el instituto qres le presenta los siguientes planes y
                        programas de capacitación en sus diferentes módulos de
                        acuerdo con las dos modalidades más recurrentes de las
                        empresas de seguridad privada que son:
                      </p>

                      <ul className={styles.flatList}>
                        <li>
                          <div className={styles.content}>
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div>Seguridad privada en los bienes.</div>
                          </div>
                        </li>
                        <li>
                          <div className={styles.content}>
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div>
                              Seguridad privada en el traslado de bienes o
                              valores sub-modalidad B) vigilancia.
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Parallax
        bgImage={bgParalax1}
        bgImageAlt="Parallax background"
        className={_classNames("joinUs")}
        strench={800}
      >
        <div className={_classNames("overlay", "bg222")}>
          <Container>
            <Row>
              <div className={styles.counterContent}>
                <span className={styles.counterPrefix}>Más de</span>

                <InView>
                  {({ inView, ref, entry }) => (
                    <div ref={ref} className={styles.numbCounter}>
                      {inView && (
                        <CountUp start={0} end={150} delay={0}>
                          {({ countUpRef }) => (
                            <div
                              className={styles.numbCount}
                              ref={countUpRef}
                            ></div>
                          )}
                        </CountUp>
                      )}
                    </div>
                  )}
                </InView>

                <span className={styles.counterSuffix}>Cursos</span>
              </div>
              <p>
                Se imparte capacitación con instructores externos,
                independientes e internos, ex militares y profesores, con basta
                experiencia ininterrumpidamente registrados ante la Secretaria
                de Trabajo y Previsión Social desde 1996, cuenta con
                certificaciones y diplomados en el extranjero en los países como
                son: Israel, Colombia, Panamá, Estados unidos, Bélgica,
                Alemania, Cuba, España y Argentina. En sistemas de seguridad
                integral en general.
              </p>
              <Link
                to="contacto"
                className={_classNames("flatButton", "centerBtn")}
              >
                Ver Cursos
              </Link>
            </Row>
          </Container>
        </div>
      </Parallax>
      <section
        className={_classNames("flatRow", "padBottom30")}
        style={{ textAlign: "justify" }}
      >
        <Container>
          <Row>
            <Col md={12} sm={12} className={styles.flatPdr100}>
              <h1 className={styles.titleCourse}>Infografía Del Director</h1>
              <p className={styles.flatLh25}>
                <strong>
                  {" "}
                  Instituto QRES Capacitación Evaluación Y Certificación En
                  Seguridad Integral S. de R.L. de C.V.{" "}
                </strong>{" "}
                Nació de la imperiosa necesidad de capacitar a todo el personal
                de seguridad privada misma que está legalmente constituida para
                llevar a cabo sus objetos sociales. Así mismo cuenta con
                autorización y registro de la secretaria de trabajo y previsión
                social como agente capacitador externo con el numero
                QCE-190321-TW7-0013.
              </p>

              <Col md={5} sm={12} className={styles.imgContainer}>
                <img src={director} alt="" />
              </Col>

              <p>
                Tte. De infantería paracaidista retirado y profesor de educación
                física deportes y recreación. Quirino ruíz vázquez. Empresario
                mexicano en seguridad privada fundador director general y
                académico.
              </p>

              <p>
                5°instructor en seguridad privada avalado por la secretaria de
                trabajo y previsión social en el país, reconocido y galardonado
                por la trayectoria en la capacitación por la contribución por
                más de 25 años con actividades de instructor a la consolidación
                de una nueva cultura de capacitación en beneficio del
                crecimiento productivo laboral en méxico.
              </p>

              <p>
                Asesor, conferencista; escritor de diversos manuales operativos
                y textos de la seguridad privada en todos los sectores de la
                iniciativa privada.
              </p>

              <p>
                Elaboración de cursos de capacitación en seguridad privada
                integral incorporados a la secretaria de trabajo y previsión
                social 80 cursos registrados ante dicha dependencia.
              </p>

              <p>
                Profesional certificado por conocer en 2 estándares de
                competencia laboral en seguridad privada. Ec-0076 evaluación de
                la competencia de candidatos en estándares de competencia.
                Ec-0574 supervisión de los servicios de seguridad privada.
              </p>

              <p>
                Diplomado en gestión de la seguridad integral en instalaciones
                críticas y estratégicas grupo de estudios técnicos "GET"
                (españa).
              </p>

              <p>
                Fundador y vicepresidente de la UNESPA, A.C. unión nacional de
                empresas de seguridad privada y adherentes, A.C. 2015-2019.
              </p>
              <p>
                Actualmente activo en operaciones como: fundador y director
                general y académico del instituto qres capacitación, evaluación
                y certificación en seguridad integral S. De R.L. de C.V.
              </p>

              <p>
                Fundador director general y activo en operaciones del grupo
                jezamk seguridad privada y custodias, quirmar technologies, y
                visor seguridad privada inteligente todas s.a. de c.v.
              </p>

              <p>
                Ampliamente reconocido en latinoamérica por su liderazgo,
                conocimientos, experiencia y aportes a la disciplina de la
                seguridad privada, y como ser humano íntegro, destacado por su
                profesionalismo, alto sentido social, investigativo, académico,
                y educativo al servicio de la sociedad en general.
              </p>

              <p>
                Distinguido oficial retirado, quien desde muy joven empezó a
                modelar su futuro en el área de la seguridad privada y
                protección. Con tenacidad, inteligencia, estudio permanente y
                las virtudes que con esmero cultivó.
              </p>

              <p>
                Paso a paso forjo el primer gran reto de la educación en
                seguridad privada, terreno que exploró desde distintos ángulos y
                facetas, estudiando las distintas corrientes del pensamiento
                latinoamericano, oriental y occidental hasta convertirse en el
                gran maestro del arte de la seguridad privada integral.
              </p>

              <p>
                Sus continuos viajes a diferentes regiones y países le
                permitieron conocer las tendencias de la seguridad pública y
                privada. Llevándolo a crear y estructurar un novedoso modelo de
                seguridad privada que denominó FIESTAINSEPRI (Filosofía
                Estratégica Táctica Inteligente en Seguridad Privada Integral)
                una nueva visión en el arte de la seguridad privada y
                protección, fundamentada en el pensamiento divergente que rompe
                la lógica racional, que fortalece la intuición. La búsqueda del
                conocimiento y la práctica de la inteligencia estratégica,
                basada no solo en principios filosóficos, sino en el desarrollo
                espiritual y humano para aportar en la construcción de la
                sociedad en general personal de seguridad privada y
                profesionales.
              </p>

              <p>
                Ha realizado un sinnúmero de investigaciones, diplomados,
                cursos, seminarios, y eventos académicos, con los cuales ha
                logrado congregar a profesionales y ejecutivos de diversas
                disciplinas nacionales e internacionales.
              </p>

              <p>
                Construyendo de esta manera la base para el compromiso actual de
                instituciones y universidades ampliamente reconocidas, a
                incorporar en sus conocimientos académicos, el estudio de las
                sub-especialidades de la seguridad privada.
              </p>

              <p>
                Construyendo de esta manera el camino hacia el crecimiento y
                fortalecimiento de la profesionalización de la seguridad privada
                en méxico.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
