import React, { useState, useEffect } from "react";
import SectionHeader from "../../components/SectionHeader/index";
import bgParalax from "./images/bg-parallax6.jpg";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import chunk from "lodash/chunk";
import Pagination from "../../components/Pagination/index";
import { defaultBlogData } from "../../shared/data";
import Axios from "axios";
import { remoteBaseUrl } from "../../services/api";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Blog(props) {
  const limit = 8;
  const myRef = React.useRef(null);
  const defaultBlog = defaultBlogData(limit);

  const getPaginationHistory = () => {
    const { state } = props.location;
    if (state && state.data) {
      return state.data;
    }
    return defaultBlog;
  };

  const [news, setDataNews] = useState([]);
  const [paginationData, setPagination] = useState(getPaginationHistory());

  useEffect(() => {
    Axios.get(
      `${remoteBaseUrl}/news?field=date&limit=${paginationData.limit}&offset=${paginationData.offset}&order=desc`
    ).then((res) => {
      const pageCount = Math.ceil(res.data.count / limit);
      setDataNews({
        count: res.data.count,
        pageCount: pageCount,
        data: res.data.data,
      });
      if (paginationData.id !== 0 || paginationData.offset !== 0) {
        myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    });
  }, [paginationData]);

  let rows = [];

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={1000}
        title={"BLOG"}
        parent={{ title: "Blog" }}
      />
      <section
        className={_classNames(
          "mainContent",
          "blogPosts",
          "blogGrid",
          "haveSidebar"
        )}
        ref={myRef}
      >
        <Container>
          <div className={styles.flatTitleSection}>
            <h1 className={styles.title}>NOTICIAS & BLOG</h1>
          </div>
          {(news.data || []).forEach((item, key) => {
            rows.push(
              <Col
                md={6}
                xs={12}
                className={_classNames("post", "flatHoverZoom")}
                key={key}
              >
                <div className={styles.featuredPost}>
                  <div className={styles.entryImage}>
                    <Link
                      to={{
                        pathname: `blog/detalle/${item.id}`,
                        state: {
                          data: defaultBlog,
                        },
                      }}
                    >
                      <img src={item.image} alt="" />
                    </Link>
                  </div>
                </div>
                <div className={styles.datePost}>
                  <span className={styles.numb}>{item.day}</span>
                  <span>{item.month}</span>
                </div>
                <div className={styles.contentPost}>
                  <h2 className={styles.titlePost}>
                    <Link
                      to={{
                        pathname: `blog/detalle/${item.id}`,
                        state: {
                          data: defaultBlog,
                        },
                      }}
                    >
                      {item.title}
                    </Link>
                  </h2>
                  <div className={styles.entryContent}>
                    <p>{item.short_description}</p>
                  </div>
                </div>
              </Col>
            );
          })}
          {chunk(rows, 2).map(function (group, key) {
            return (
              <Row className={styles.postLastestNew} key={key}>
                {group}
              </Row>
            );
          })}
          {(news.data || []).length > 0 && (
            <Pagination
              pageCount={news.pageCount}
              onPageChange={setPagination}
              data={paginationData}
            />
          )}
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
