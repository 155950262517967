import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import classNames from "classnames/bind";
import Sticky from "react-sticky-el";
import styles from "./index.module.scss";
import Top from "./Top";
import logo from "./images/logo.png";
const _classNames = classNames.bind(styles);

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <React.Fragment>
      <Top />
      <Sticky>
        <header id="header" className={_classNames("header", "clearfix")}>
          <Container>
            <div className={_classNames("headerWrap", "clearfix")}>
              <div className={styles.logo}>
                <Link to="/">
                  <img src={logo} alt="Instituto QRES" />
                </Link>
              </div>
              <div className={styles.navWrap}>
                <div
                  className={_classNames("btnMenu", { active: showMenu })}
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <span></span>
                </div>
                <nav id="mainnav" className={styles.mainnav}>
                  <ul className={styles.menu}>
                    <li>
                      <Link to="/">Inicio</Link>
                    </li>
                    <li>
                      <Link to="/nosotros">Nosotros</Link>
                    </li>
                    <li>
                      <Link to="/programas">Programas</Link>
                    </li>
                    <li>
                      <Link to="/lista-de-cursos">Lista de cursos</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/clientes">Clientes</Link>
                    </li>
                    <li>
                      <Link to="/contacto">Contacto</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </Container>
        </header>
        <nav
          id="mainnav-mobi"
          className={_classNames("mainnav", { showMenu: showMenu })}
        >
          <ul className={styles.menu}>
            <li>
              <Link to="/" onClick={() => setShowMenu(false)}>
                Inicio
              </Link>
            </li>
            <li>
              <Link to="/nosotros" onClick={() => setShowMenu(false)}>
                Nosotros
              </Link>
            </li>
            <li>
              <Link to="/programas" onClick={() => setShowMenu(false)}>
                Programas
              </Link>
            </li>
            <li>
              <Link to="/lista-de-cursos" onClick={() => setShowMenu(false)}>
                Lista de cursos
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={() => setShowMenu(false)}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/clientes" onClick={() => setShowMenu(false)}>
                Clientes
              </Link>
            </li>
            <li>
              <Link to="/contacto" onClick={() => setShowMenu(false)}>
                Contacto
              </Link>
            </li>
          </ul>
        </nav>
      </Sticky>
    </React.Fragment>
  );
}
