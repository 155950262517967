import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax, Background } from "react-parallax";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export default function SectionHeader(props) {
  return (
    <Parallax strength={props.strench}>
      {/* bgImage={props.bgImage} bgImageAlt="Parallax background"> */}
      <Background className="custom-bg">
        <div
          style={{
            height: 2000,
            width: 2000,
            backgroundImage: `url('${props.bgImage}')`,
          }}
        />
      </Background>
      <div className={styles.pageTitle}>
        <div className={styles.overlay}></div>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.pageTitleHeading}>
                <h2>{props.title}</h2>
              </div>
              <div className={styles.breadcrumbs}>
                <ul>
                  <li className={styles.home}>
                    <Link to="/">Inicio</Link> {props.parent ? ">" : ""}
                  </li>
                  {props.parent && (
                    <li>
                      {props.parent.pagination ? (
                        <React.Fragment>
                          <Link
                            to={{
                              pathname: props.parent.url,
                              state: {
                                data: props.parent.pagination,
                              },
                            }}
                          >
                            {props.parent.title}
                          </Link>{" "}
                          {"> Detalle"}
                        </React.Fragment>
                      ) : (
                        props.parent.title
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Parallax>
  );
}
