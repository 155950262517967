import React, { useState, useEffect } from "react";
import bgParalax from "./images/bg-parallax4.jpg";
import SectionHeader from "../../components/SectionHeader/index";
import styles from "./index.module.scss";
import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/bind";
import { remote, remoteBaseUrl } from "../../services/api";
import useFetch from "../../hooks/useFetch";
import Axios from "axios";
import { Link } from "react-router-dom";
import chunk from "lodash/chunk";
import Pagination from "../../components/Pagination/index";
import { defaultCategoryData } from "../../shared/data";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Programs(props) {
  const limit = 9;
  const myRef = React.useRef(null);
  const [categories, setDataCategories] = useState([]);
  const [programs, setDataPrograms] = useState({
    count: 0,
    pageCount: 0,
    data: [],
  });

  const defaultCategory = defaultCategoryData(limit);

  const getPaginationHistory = () => {
    const { state } = props.location;
    if (state && state.data) {
      return state.data;
    }
    return defaultCategory;
  };

  const [categoryData, setCategory] = useState(getPaginationHistory());

  const { response: CategoriesEndpoint } = useFetch({
    api: remote,
    url: "/programs/categories?order=asc",
  });

  useEffect(() => {
    if (CategoriesEndpoint !== null) {
      setDataCategories(CategoriesEndpoint);
    }
  }, [CategoriesEndpoint]);

  useEffect(() => {
    Axios.get(
      `${remoteBaseUrl}/programs?field=id&limit=${categoryData.limit}&offset=${
        categoryData.offset
      }&order=asc${categoryData.id !== 0 ? `&category=${categoryData.id}` : ""}`
    ).then((res) => {
      const pageCount = Math.ceil(res.data.count / limit);
      setDataPrograms({
        count: res.data.count,
        pageCount: pageCount,
        data: res.data.data,
      });
      if (categoryData.id !== 0 || categoryData.offset !== 0) {
        myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    });
  }, [categoryData]);

  let rows = [];

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={"Conoce nuestros programas"}
        parent={{ title: "Programas" }}
      />
      <section
        className={_classNames(
          "mainContent",
          "blogPosts",
          "flatRow",
          "courseGrid"
        )}
        ref={myRef}
      >
        <Container className={_classNames("container", "customContainer")}>
          <div className={_classNames("blogTitle", "clearfix")}>
            <h1 className="bold">Programas</h1>
          </div>
          <Row>
            <div className={_classNames("flatPostOrdering", "clearfix")}>
              <div className={styles.sortViews}>
                <select
                  value={categoryData.id}
                  name="select_category"
                  className="orderby"
                  onChange={(e) =>
                    setCategory({
                      ...defaultCategory,
                      id: e.currentTarget.value,
                      offset: 0,
                    })
                  }
                >
                  <option value="0">Seleciona una Categoria</option>
                  {(categories.data || []).map((category) => {
                    return (
                      <option value={category.id} key={category.id}>
                        {category.category}
                      </option>
                    );
                  })}
                </select>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={styles.chevron}
                />
              </div>
            </div>
            <div className={_classNames("postWrap", "clearfix")}>
              {(programs.data || []).forEach((item, key) => {
                rows.push(
                  <div className={styles.flatCourse} key={key}>
                    <div className={styles.featuredPost}>
                      <div className="overlay">
                        <div className="link"></div>
                        <Link
                          to={{
                            pathname: `programas/detalle/${item.id}`,
                            state: {
                              data: categoryData,
                            },
                          }}
                        >
                          <img src={item.image} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className={styles.courseContent}>
                      <h4>
                        <Link
                          to={{
                            pathname: `programas/detalle/${item.id}`,
                            state: {
                              data: categoryData,
                            },
                          }}
                        >
                          {item.title}
                        </Link>
                      </h4>
                      <div className={styles.price}>{item.price}</div>
                      <p>{item.short_description}</p>
                      <ul className={_classNames("courseMeta", "desc")}>
                        <li>
                          <h6>{item.module}</h6>
                          <span>Modulo</span>
                        </li>
                        <li>
                          <h6>{`${item.duration} Horas`}</h6>
                          <span>Duracción</span>
                        </li>
                        <li>
                          <h6>
                            <span>{item.date}</span>
                          </h6>
                          <span>Fecha</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
              {chunk(rows, 3).map(function (group, key) {
                return (
                  <Row key={key} className={styles.elements}>
                    {group}
                  </Row>
                );
              })}
            </div>
            {programs.data.length > 0 && (
              <Pagination
                pageCount={programs.pageCount}
                onPageChange={setCategory}
                data={categoryData}
              />
            )}
          </Row>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
