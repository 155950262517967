import React, { useState, useEffect } from "react";
import bgParalax from "./images/bg-parallax6.jpg";
import SectionHeader from "../../../components/SectionHeader/index";
import useFetch from "../../../hooks/useFetch";
import { remote } from "../../../services/api";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import WhatsappButton from "../../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function BlogDetail(props) {
  const [blog, setDataBlog] = useState([]);

  const { response: BlogEndpoint } = useFetch({
    api: remote,
    url: `/new/${props.match.params.id}`,
  });

  const getPaginationHistory = () => {
    const { state } = props.location;
    if (state && state.data) {
      return state.data;
    }
    return {};
  };

  useEffect(() => {
    if (BlogEndpoint !== null) {
      console.log(BlogEndpoint);
      setDataBlog(BlogEndpoint);
    }
  }, [BlogEndpoint]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={`BLOG`}
        parent={{
          title: "blog",
          url: "/blog",
          pagination: getPaginationHistory(),
        }}
      />
      <section
        className={_classNames("mainContent", "blogPosts", "courseSingle")}
      >
        <Container>
          <div className={styles.blogTitleSingle}>
            <h1 className={_classNames("bold")}>{blog.title}</h1>
            <Container>
              <Row>
                <Col md={7} className={styles.featurePost}>
                  <img src={blog.image} alt={blog.title} />
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  className={_classNames("featurePost", "entryContent")}
                >
                  {(blog.description || "").split("\n").map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
