import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./index.module.scss";

export default function Pagination(props) {
  const pageChangeHandler = (data) => {
    let selected = data.selected;
    props.onPageChange({ ...props.data, offset: selected });
  };

  return (
    <div className={styles.containerPagination}>
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={pageChangeHandler}
        containerClassName={styles.flatPagination}
        // subContainerClassName={"pagination"}
        activeClassName={styles.active}
        forcePage={props.data.offset || 0}
      />
    </div>
  );
}
