export const defaultCategoryData = (limit) => ({
  id: 0,
  limit: limit || 0,
  offset: 0,
});

export const defaultBlogData = (limit) => ({
  limit: limit || 0,
  offset: 0,
});
