import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TemplateApp } from "./components/TemplateApp";
import "./styles/index.scss"
import Home from './pages/Home/index';
import Programs from './pages/Programs/index';
import ProgramsDetail from './pages/Programs/details/index';
import Courses from './pages/Courses/index';
import Clients from './pages/Clients/index';
import Contacts from './pages/Contacts/index';
import Blog from "./pages/Blog";
import BlogDetail from './pages/Blog/details/index';
import About from './pages/About/index';

function App() {
  return (
    <Router>
      <TemplateApp>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/nosotros" component={About} />
          <Route exact path="/programas" component={Programs} />
          <Route path="/programas/detalle/:id" component={ProgramsDetail} />
          <Route exact path="/lista-de-cursos" component={Courses} />
          <Route exact path="/clientes" component={Clients} />
          <Route exact path="/contacto" component={Contacts} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/detalle/:id" component={BlogDetail} />
        </Switch>
      </TemplateApp>
    </Router>
  );
}

export default App;