import axios from "axios";

export const localBaseUrl = "http://localhost";
export const remoteBaseUrl = "https://api.v1.qres.com.mx";

export const local = axios.create({
  baseURL: localBaseUrl
});

export const remote = axios.create({
  baseURL: remoteBaseUrl
});