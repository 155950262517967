import React, { useState, useEffect } from "react";
import SectionHeader from "../../components/SectionHeader/index";
import bgParalax from "./images/bg-parallax4.jpg";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import useFetch from "../../hooks/useFetch";
import { remote } from "../../services/api";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Courses() {
  const [courses, setDataCourses] = useState([]);

  const { response: CoursesEndpoint } = useFetch({
    api: remote,
    url: "/courses?fields=prefix,position&orders=asc,asc&limit=none",
  });

  useEffect(() => {
    if (CoursesEndpoint !== null) {
      setDataCourses(CoursesEndpoint);
    }
  }, [CoursesEndpoint]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={"PROGRAMA DE CAPACITACIÓN PARA GUARDIAS"}
        parent={{ title: "Listado de cursos" }}
      />
      <section
        className={_classNames("mainContent", "blogPosts", "courseSingle")}
      >
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.blogTitleSingle}>
                <h1 className={_classNames("bold")}>
                  Listado de cursos y programas de capacitacion del Agente
                  Capacitador
                </h1>
                <div className={_classNames("col-md-12", "customTable")}>
                  <div
                    className={_classNames("customRow", "customHeader", "blue")}
                  >
                    <div className={styles.customCell}>Nº</div>
                    <div className={styles.customCell}>PROGRAMAS O CURSOS</div>
                    <div className={styles.customCell}>AREA TEMÁTICA</div>
                    <div className={styles.customCell}>DURACIÓN HRS</div>
                  </div>
                  {(courses.data || []).map((course, key) => {
                    return (
                      <div className={styles.customRow} key={key}>
                        <div className={styles.customCell}>
                          {course.prefix}
                          {course.position}
                        </div>
                        <div className={styles.customCell}>{course.title}</div>
                        <div className={styles.customCell}>
                          {course.category}
                        </div>
                        <div className={styles.customCell}>
                          {course.duration}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
