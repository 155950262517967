import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./index.module.scss";

export default function Top() {
  return (
    <div className={styles.headerInnerPages}>
      <div className={styles.top}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.textInformation}>
                <p>
                  <Link to="contacto">¿Tienes alguna pregunta?</Link>
                </p>
              </div>
              <div className={styles.rightBar}>
                <ul className={styles.flatInformation}>
                  <li>
                    <a href="tel:3328348312" title="Phone number">
                      <FontAwesomeIcon icon={faPhoneAlt} size="xs" />
                      33 2834 8312
                    </a>
                    <a href="tel:3319186535" title="Phone number">
                      33 1918 6535
                    </a>
                  </li>
                  <li className={styles.email}>
                    <a href="mailto:info@qres.com.mx" title="Email address">
                      <FontAwesomeIcon icon={faEnvelope} />
                      info@qres.com.mx
                    </a>
                  </li>
                </ul>
                <ul className={styles.flatSocials}>
                  <li className={styles.facebook}>
                    <a
                      href="https://www.facebook.com/institutoqres"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon size="lg" icon={faFacebookF} />
                    </a>
                  </li>
                  <li className={styles.instagram}>
                    <a href="/#">
                      <FontAwesomeIcon size="lg" icon={faInstagram} />
                    </a>
                  </li>
                  <li className={styles.linkedin}>
                    <a href="/#">
                      <FontAwesomeIcon size="lg" icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li className={styles.youtube}>
                    <a href="/#">
                      <FontAwesomeIcon size="lg" icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
