import React from "react";
import TinySlider from "tiny-slider-react";

export default function MicroSlider(props) {
  const settings = {
    lazyload: true,
    nav: false,
    loop: true,
    gutter: props.gutter || 30,
    mouseDrag: true,
    items: props.items || 3,
    autoplay: props.autoplay || true,
    autoplayButtonOutput: false,
    controls: false,
    responsive: {
      360: {
        items: 2,
      },
      991: {
        items: props.items,
      },
    },
  };

  return (
    <TinySlider settings={settings}>
      {props.content.data.map((item, key) => (
        <img src={item.small_image} alt="" key={key} />
      ))}
    </TinySlider>
  );
}
